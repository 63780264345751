import Header from './header'
import Navigation from './navigation'
import Theme from './theme'
import style from './style.module.css'

const Layout = ({ event, children }) => {

    return (
        <>
            <div className={style.layout}>
                <Header event={event} />
                <Navigation event={event} />
                <div className={style.content + ' event-content-area'}>
                    {children}
                </div>
            </div>
            <Theme event={event} />
        </>
    )

}

export default Layout