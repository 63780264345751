import { useState, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button, Spinner, Alert, Card } from 'react-bootstrap'
import { Formik } from 'formik'
import { imgBaseUrl } from 'lib/cloudinary'
import Field from 'components/formik/field'
import PoweredBy from 'components/brand/powered-by'
import { loadEvent, clearEvent } from 'store/event'
import { loadGuest, clearGuest } from 'store/guest'
import useQuery from 'hooks/use-query'
import * as yup from 'yup'
import api from 'lib/api'
import style from './style.module.css'
import { ChevronLeft } from 'react-feather'

const Gate = ({ children }) => {

  const query = useQuery()
  const [error, setError] = useState()
  const [loading, setLoading] = useState(true)
  const [eventId, userId] = useSelector(state => [state.event?.id, state.guest?.id])
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    const eventCode = query.get('e')
    // history.replace('/')
    navigate('/')
    const joinEvent = event => {
      return new Promise(resolve => {
        dispatch(loadEvent(event))
        if (userId) {
          api.post('/event/' + eventId + '/join', {
            guestId: userId
          })
          .then(guest => {
            if (!guest) {
              dispatch(clearGuest())
            } else if (guest.verified) {
              dispatch(loadGuest(guest))
              api.get('/event/' + event.id)
              .then(event => {
                dispatch(loadEvent(event))
              })
              .catch(setError)
            }
            setError()
            resolve()
          })
          .catch(error => {
            console.error(error)
            dispatch(clearGuest())
            resolve()
          })
        } else {
          resolve()
        }
      })
    }
    if (eventCode) {
      api.put('/event', { code: eventCode })
        .then(joinEvent)
        .catch(error => {
          console.error(error)
          dispatch(clearGuest())
        })
        .finally(() => setLoading())
    } else if (eventId) {
      api.get('/event/' + eventId)
        .then(joinEvent)
        .catch(error => {
          console.error(error)
          dispatch(clearEvent())
          dispatch(clearGuest())
        })
        .finally(() => setLoading())
    } else {
      setLoading()
    }
  }, [])

  return error ? error.message :
    loading ? <Loading /> :
    !eventId ? <EventCode /> :
    !userId ? <Onboarding /> :
    // !userValid ? <Validate /> :
    children

}

export default Gate

const Loading = () => {
  return (
    <div className={style.gateContainer}>
      <Spinner />
    </div>
  )
}

const EventCode = () => {

  const [error, setError] = useState()
  const [loading, setLoading] = useState()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const eventCodeSchema = yup.object().shape({
    code: yup.string().length(6).required().label(t('eventCode'))
  })

  const handleSubmit = values => {
    setLoading(true)
    api.put('/event', values)
      .then(event => {
        if (!event) throw new Error('No event returned')
        setError()
        dispatch(loadEvent(event))
      })
      .catch(setError)
      .finally(() => setLoading())
  }

  return (
    <div className={style.gateContainer}>
      <div />
      <Card body className={style.gateCard}>
        <Formik initialValues={{ code: '' }} validationSchema={eventCodeSchema} onSubmit={handleSubmit}>
          {({ handleSubmit, isValid }) => (
            <form noValidate onSubmit={handleSubmit}>
              <h5 className='text-center mb-3'>{t('eventCodePrompt')}</h5>
              <Field type='text' name='code' autoComplete='off' />
              {error && <Alert variant='warning'>{error.message}</Alert>}
              <div className='text-center mt-3'>
                <Button type='submit' size='lg' disabled={loading || !isValid}>{t('join')}</Button>
              </div>
            </form>
          )}
        </Formik>
      </Card>
      <PoweredBy />
    </div>
  )
  
}

const verifySchema = yup.object().shape({
  code: yup.string().length(6).required()
})

const Onboarding = () => {

  const [error, setError] = useState()
  const [loading, setLoading] = useState()
  const [verify, setVerify] = useState()
  const event = useSelector(state => state.event)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const onboardingSchema = useMemo(() => yup.object().shape({
    name: yup.string().min(2).max(100).required().label(t('name')),
    email: yup.string().email().max(100).required().label(t('email')),
    field1: event?.fields?.length > 0 ?
        event.fields[0].required ?
            yup.string().max(100).required().label(event.fields[0].label)
            : yup.string().max(100).label(event.fields[0].label)
        : yup.string().nullable(),
    field2: event?.fields?.length > 1 ?
        event.fields[1].required ?
            yup.string().max(100).required().label(event.fields[1].label)
            : yup.string().max(100).label(event.fields[1].label)
        : yup.string().nullable()
  }), [event, t])

  const initialValues = {
      name: '',
      email: '',
      field1: '',
      field2: ''
  }

  const handleContinue = values => {
    setLoading(true)
    api.post('/guest', {
      ...values,
      eventId: event.id
    })
      .then(user => {
        if (!user) throw new Error('No attendee returned')
        setError()
        if (user.verified) {
          api.post('/event/' + event.id + '/join', {
            code: null,
            guestId: user.id
          })
          .then(user => {
            if (!user) throw new Error('No attendee returned')
            setError()
            if (user.verified) {
              dispatch(loadGuest(user))
              // api.get('/event/' + event.id)
              // .then(event => {
              //   dispatch(loadEvent(event))
              // })
              // .catch(setError)
            }
            // history.replace('/')
          })
          .catch(setError)
        } else {
          setVerify(user.id)
        }
      })
      .catch(setError)
      .finally(() => setLoading())
  }

  const handleConfirm = values => {
    setLoading(true)
    api.post('/event/' + event.id + '/join', {
      code: String(values.code),
      guestId: verify
    })
      .then(user => {
        if (!user) throw new Error('No attendee returned')
        setError()
        if (user.verified) {
          dispatch(loadGuest(user))
        }
      })
      .catch(setError)
      .finally(() => setLoading())
  }

  const handleLeaveEvent = () => {
    dispatch(clearEvent())
  }

  return (
    <div className={style.gateContainer}>
      <div />
      <div className={style.gateCard}>
        {verify ? (
          <Card body>
            <Button variant='link' className='mb-4 p-0 text-decoration-none' onClick={() => setVerify(false)}>
              <ChevronLeft />
              {t('back')}
            </Button>
            <Formik initialValues={{ code: '' }} validationSchema={verifySchema} onSubmit={handleConfirm}>
              {({ handleSubmit, isValid }) => (
                <form noValidate onSubmit={handleSubmit}>
                  <Field name='code' type='number' label={t('sixDigitCodePrompt')} autoComplete='off' />
                  {error && <Alert>{error.message}</Alert>}
                  <div className='text-center mt-4'>
                    <Button type='submit' disabled={loading || !isValid}>{t('confirm')}</Button>
                    <br /><br />
                    <Button type='button' variant='link' size='sm' className='text-muted'>{t('resendEmail')}</Button>
                  </div>
                </form>
              )}
            </Formik>
          </Card>
        ) : (
          <Card body>
            <h3 className={style.gateLogo}>
              {!!event.logoUrl ? (
                <img src={imgBaseUrl + '/t_scale_300/' + event.logoUrl} alt={event.name} title={event.name} />
                // <img src={event.logoUrl} alt={event.name} title={event.name} />
              ) : (
                event.name
              )}
            </h3>
            <Formik initialValues={initialValues} validationSchema={onboardingSchema} onSubmit={handleContinue}>
              {({ handleSubmit, isValid }) => (
                <form noValidate onSubmit={handleSubmit}>
                  <Field name='name' type='text' label={t('name')} />
                  <Field name='email' type='email' label={t('email')} />
                  {event?.fields?.length > 0 && event.fields.map((field, index) => field.type === 'select' ? (
                    <Field key={index} name={'field' + (index + 1)} label={field.label} type={field.type}>
                      <option />
                      {field.options?.map((option, index) => (
                        <option key={index} value={typeof option === 'string' ? option : option.value}>{typeof option === 'string' ? option : option.name}</option>
                      ))}
                    </Field>
                  ) : (
                    <Field key={index} name={'field' + (index + 1)} label={field.label} type={field.type} autoComplete='off' />
                  ))}
                  {error && <Alert variant='warning'>{error.message}</Alert>}
                  <div className='text-center mt-4'>
                    <Button type='submit' size='lg' disabled={loading || !isValid}>{t('continue')}</Button>
                  </div>
                </form>
              )}
            </Formik>
          </Card>
        )}
        <div className='text-center py-3 small text-muted'>
          {t('wrongEvent')} <Button variant='link' className='small p-0 m-0 d-inline' style={{ verticalAlign: 'baseline', cursor: 'pointer', lineHeight: 'inherit', fontSize: 'inherit' }} onClick={handleLeaveEvent}>{t('leaveEvent')}</Button>
        </div>
      </div>
      <PoweredBy />
    </div>
  )

}










// const verifySchema = yup.object().shape({
//   code: yup.string().length(6).required()
// })

// const Validate = () => {

//   const [error, setError] = useState()
//   const [loading, setLoading] = useState()
//   const event = useSelector(state => state.event)
//   const dispatch = useDispatch()
//   const { t } = useTranslation()

//   const handleSubmit = values => {
//     setLoading(true)
//     api.post('/event/' + event.id + 'attendee', values)
//       .then(user => {
//         dispatch(loadGuest(user))
//       })
//       .catch(setError)
//       .finally(() => setLoading())
//   }

//   return (
//     <div className={style.gateContainer}>
//       <div />
//       <Card body className={style.gateCard}>
//         <Button variant='link' className='mb-4 p-0'>Go back</Button>
//         <Formik initialValues={{ code: '' }} validationSchema={verifySchema} onSubmit={handleSubmit}>
//           {({ handleSubmit, isValid }) => (
//             <form noValidate onSubmit={handleSubmit}>
//               <Field name='code' type='number' label={t('sixDigitCodePrompt')} />
//               {error && <Alert>{error.message}</Alert>}
//               <div className='text-center'>
//                 <Button type='submit' disabled={loading || !isValid}>{t('confirmButton')}</Button>
//                 <br /><br />
//                 <Button type='button' variant='link' size='sm'>Resend email</Button>
//               </div>
//             </form>
//           )}
//         </Formik>
//       </Card>
//       <PoweredBy />
//     </div>
//   )

// }