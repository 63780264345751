import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, Button, Modal } from 'react-bootstrap'
import { ExternalLink as ExternalLinkIcon } from 'react-feather'
import { MessageSquare as MessageSquareIcon } from 'react-feather'
import { CheckCircle as CircleCheckIcon } from 'react-feather'
import { imgBaseUrl } from 'lib/cloudinary'
import AreaImg from 'components/area-img'
import useActions from 'hooks/use-actions'

const SponsorView = ({ sponsor, toggle, fullscreen }) => {

    const { actions, doAction } = useActions('sponsor')
    const [connected, setConnected] = useState(false)
    const [error, setError] = useState()
    const { t } = useTranslation()

    const handleConnect = () => {
        if (!sponsor) return false
        doAction('connect', sponsor.id) // { sponsorId: sponsor.id })
        .then(() => {
            setError()
            setConnected(true)
        })
        .catch(setError)
    }

    useEffect(() => {
        setConnected(actions.findIndex(action => action.entity === sponsor?.id) > -1)
    }, [sponsor])
    
    return (
        <Modal show={!!sponsor} onHide={toggle} centered fullscreen={fullscreen}>
            {sponsor && (
                <>
                    <Modal.Header closeButton onHide={toggle}>{sponsor.name}</Modal.Header>
                    <Modal.Body>
                        {/* {!!sponsor.imageUrl && <p><img src={sponsor.imageUrl} alt={sponsor.name} style={{ maxHeight: '5rem', maxWidth: '15rem' }} /></p>} */}
                        {!!sponsor.imageUrl && <p><AreaImg src={imgBaseUrl + '/t_scale_300/' + sponsor.imageUrl} alt={sponsor.name} area={'40rem'} /></p>}
                        {!!sponsor.detail && <h4>{sponsor.detail}</h4>}
                        {!!sponsor.description && <p className='small mb-2'>{sponsor.description}</p>}
                        {!!sponsor.linkUrl && <p>
                            <a href={sponsor.linkUrl} target='_blank' rel='noopener noreferrer'>
                                {t('visitWebsite')}
                                <ExternalLinkIcon style={{ height: '1rem', verticalAlign: 'top', marginTop: '0.25rem' }} />
                            </a>
                        </p>}
                    </Modal.Body>
                    {error && <Alert variant='warning' style={{ margin: 0, borderRadius: 0 }}>{error.message}</Alert>}
                    <Button variant={connected ? 'secondary' : 'primary'} style={{ cursor: connected ? 'default' : 'pointer', padding: '1rem', borderTopLeftRadius: 0, borderTopRightRadius: 0 }} onClick={handleConnect}>
                        {connected ? (
                            <>
                                <CircleCheckIcon style={{ marginRight: '0.5rem' }} />
                                {t('connectedWithName', { name: sponsor.name })}
                            </>
                        ) : (
                            <>
                                <MessageSquareIcon style={{ marginRight: '0.5rem' }} />
                                {t('connectWithName', { name: sponsor.name })}
                            </>
                        )}
                    </Button>
                </>
            )}
        </Modal>
    )
}

export default SponsorView