import { createStore, combineReducers } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import eventReducer from './event'
import guestReducer from './guest'
import actionsReducer from './action'

const eventPersistConfig = {
  key: 'event',
  storage,
}

const guestPersistConfig = {
  key: 'guest',
  storage,
}

const actionsPersistConfig = {
  key: 'action',
  storage,
}

const rootReducer = combineReducers({
  event: persistReducer(eventPersistConfig, eventReducer),
  guest: persistReducer(guestPersistConfig, guestReducer),
  actions: persistReducer(actionsPersistConfig, actionsReducer)
})

export const store = createStore(rootReducer)
export const persistor = persistStore(store)

export default store