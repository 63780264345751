export const LOAD_ACTIONS = 'engage/actions/LOAD'
export const ADD_ACTION = 'engage/actions/ADD'
export const CLEAR_ACTIONS = 'engage/actions/CLEAR'

const initialState = () => {
    const state = Object.create(null)
    state.all = []
    return state
}

const reducer = (state = initialState(), { type, data }) => {
    switch (type) {
        case LOAD_ACTIONS:
            return {
                ...state,
                all: data || []
            }
        case ADD_ACTION:
            return {
                ...state,
                all: [ data, ...state.all ]
            }
        case CLEAR_ACTIONS:
            return initialState()
        default:
            return state
    }
}

export default reducer

export const loadActions = (data) => {
    return {
        type: LOAD_ACTIONS,
        data
    }
}

export const addAction = (data) => {
    return {
        type: ADD_ACTION,
        data
    }
}

export const clearActions = () => {
    return {
        type: CLEAR_ACTIONS
    }
}