import { NavLink } from 'react-router-dom'
import { Nav } from 'react-bootstrap'
import useFeatures from 'hooks/use-features'
import style from './style.module.css'

const Navigation = ({ event }) => {

    const features = useFeatures(event?.features)
    const activeStyle = { color: event.primaryColor }

    return (
        <div className={style.navbar}>
          <Nav className={style.nav}>
            {features.map(feature => {
                const Icon = feature.icon
                return (
                    <Nav.Item key={feature.id} as={NavLink} to={feature.url} className={style.navItem} style={({ isActive }) => isActive ? activeStyle : undefined}>
                        <Icon className={style.navIcon} />
                        <div className={style.navLabel}>{feature.name}</div>
                    </Nav.Item>
                )
            })}
          </Nav>
        </div>
    )
    
}

export default Navigation