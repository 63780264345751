import { useState, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Container, Row, Col, Card, Alert, Button } from 'react-bootstrap'
import { imgBaseUrl } from 'lib/cloudinary'
import Loading from 'components/loading'
import SpeakerView from './view'
import useSWR from 'swr'
import axios from 'axios'

const SpeakerFeature = ({ feature }) => {

    const [event, token] = useSelector(state => [state.event, state.guest?.token])
    const eventId = event?.id
    const featureId = feature?.id
    const [selected, setSelected] = useState()
    const [filter, setFilter] = useState()
    const categories = event?.categories?.filter(c => c.featureId === featureId)

    const { data: entities, error } = useSWR(
        process.env.REACT_APP_API_URL + '/client/event/' + eventId + '/speaker',
        url => axios.get(url, { headers: { authorization: 'Bearer ' + token } }).then(res => res.data),
        { refreshInterval: 10 * 1000 }
    )

    const speakers = useMemo(() => {
        if (entities && categories?.length > 0) {
            let newList = []
            let categoryId
            for (let i = 0; i < categories.length; i++) {
                categoryId = categories[i].id
                newList = newList.concat(entities.filter(e => e.categoryId === categoryId))
            }
            return newList
        } else {
            return entities
        }
    }, [entities, categories])

    useEffect(() => {
        if (categories?.length > 0 && !filter) setFilter(categories[0].id)
    }, [])

    return (
        <Container className='py-4'>
            {error ? (
                <Alert>{error.message}</Alert>
            ) : !speakers ? (
                <Loading />
            ) : (
                <>
                    {categories?.length > 0 && (
                        <ul className='category-list'>
                            {categories.map((category, index) => (
                                <li key={index}><Button style={{ width: '100%' }} variant={filter === category.id ? 'primary' : 'secondary'} onClick={() => setFilter(category.id)}>{category.name}</Button></li>
                            ))}
                        </ul>
                    )}
                    <Row xs={2} md={3} lg={4} xl={6} className='g-3 mb-4'>
                        {speakers.filter(speaker => !filter || speaker.categoryId === filter).map(speaker => (
                            <Col key={speaker.id} style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', justifyContent: 'stretch' }}>
                                <Card onClick={() => setSelected(speaker)} style={{ cursor: 'pointer', flex: 1, minHeight: '8rem', overflow: 'hidden' }}>
                                    <div className='position-relative'>
                                        {/* {!!speaker.badge && (
                                            <div style={{ position: 'absolute', top: 0, right: 0, background: event.primaryColor, color: '#fff', fontSize: '1.25rem', padding: '0 0.25rem', paddingBottom: '1.5rem', paddingLeft: '1.5rem', borderBottomLeftRadius: '0.5rem', clipPath: 'polygon(0 0, 100% 0, 100% 100%)' }}>★</div>
                                        )} */}
                                        {!!speaker.imageUrl && (
                                            <div style={{ width: '100%', overflow: 'hidden', paddingTop: '100%', backgroundColor: '#ccc', backgroundImage: 'url(' + imgBaseUrl + '/t_square_300/' + speaker.imageUrl + ')', backgroundSize: 'cover', backgroundPosition: 'center' }} />
                                        )}
                                        {!!speaker.badge && (
                                            <div style={{ position: 'absolute', bottom: 0, left: 0, right: 0, background: event.primaryColor, color: '#fff', textAlign: 'center', fontSize: '0.75rem', padding: '0.125rem', fontWeight: 'bold' }}>Winner!</div>
                                        )}
                                    </div>
                                    <Card.Body className='p-2'>
                                        <div className='mb-0'>{speaker.name}</div>
                                        {!!speaker.detail && <div className='small text-muted' style={{ lineHeight: 1.25 }}>{speaker.detail}</div>}
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                    <SpeakerView speaker={selected} toggle={() => setSelected()} />
                </>
            )}
        </Container>
    )
}

export default SpeakerFeature