import { useState, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Alert, Button, Modal } from 'react-bootstrap'
import { ExternalLink as ExternalLinkIcon } from 'react-feather'
import { ChevronLeft as LeftIcon } from 'react-feather'
import { ChevronRight as RightIcon } from 'react-feather'
// import { MessageSquare as MessageSquareIcon } from 'react-feather'
// import { CheckCircle as CircleCheckIcon } from 'react-feather'
import { imgBaseUrl } from 'lib/cloudinary'
import AreaImg from 'components/area-img'
import useActions from 'hooks/use-actions'
import StartupRating from './vote'

const StartupView = ({ featureId, startup, toggle, prev, next, fullscreen }) => {

    const [event, guest] = useSelector(state => [state.event, state.guest])
    const { actions, doAction } = useActions(featureId)
    const [connected, setConnected] = useState(false)
    // const [showMore, setShowMore] = useState(false)
    const [error, setError] = useState()
    const { t } = useTranslation()

    const handleConnect = () => {
        if (!startup) return false
        doAction(connected ? 'disconnect' : 'connect', startup.id) // { startupId: startup.id })
        .then(() => {
            setError()
            setConnected(connected ? false : true)
        })
        .catch(setError)
    }

    const categoryId = startup?.categoryId
    const category = useMemo(() => {
        return event.categories?.find(category => category.id === categoryId)
    }, [categoryId, event])

    useEffect(() => {
        const connectIndex = actions.findIndex(action => action.type === 'connect' && action.entity === startup?.id)
        const disconnectIndex = actions.findIndex(action => action.type === 'disconnect' && action.entity === startup?.id)
        setConnected(connectIndex > -1 && (disconnectIndex === -1 || connectIndex < disconnectIndex))
        // setShowMore(false)
    }, [startup]) // intentional

    // const twoPartDescription = useMemo(() => {
    //     if (!startup?.description) return
    //     const splitDesc = startup.description.split('. ').filter(s => !!s)
    //     const isLongDesc = splitDesc.length > 2 // && startup.description.length > 250
    //     if (isLongDesc) {
    //         const intro = splitDesc.slice(0, 2).join('. ') + '.'
    //         const more = splitDesc.slice(2).join('. ')
    //         return [intro, more]
    //     }
    //     return [startup.description]
    // }, [startup])
    
    return (
        <Modal show={!!startup} onHide={toggle} centered fullscreen={fullscreen}>
            {startup && (
                <>
                    <Modal.Header closeButton={!!toggle} onHide={toggle} style={{ background: '#e5e5e5' }}>{startup.name}</Modal.Header>
                    {!!startup.badge && (
                        <div style={{ background: event.primaryColor, color: '#fff', textAlign: 'center', fontSize: '1rem', padding: '0.25rem', fontWeight: 'bold' }}>{t('highestRated')}</div>
                    )}
                    <Modal.Body>
                        {/* {!!startup.imageUrl && <p><img src={startup.imageUrl} alt={startup.name} style={{ maxHeight: '5rem', maxWidth: '15rem' }} /></p>} */}
                        {!!startup.imageUrl && <p><AreaImg src={imgBaseUrl + '/t_scale_300/' + startup.imageUrl} alt={startup.name} area={'40rem'} /></p>}
                        {!!category && <p className='h6 mb-2'>{category.name}</p>}
                        {/* {!!twoPartDescription && <div className='small' style={{ lineHeight: '1.33' }}>
                            {twoPartDescription[0]}
                            {twoPartDescription.length === 2 && showMore && twoPartDescription[1]}
                            {' '}
                            {twoPartDescription.length === 2 && <button type='button' onClick={() => setShowMore(!showMore)} className='p-0 bg-transparent d-inline text-nowrap text-primary border-0' style={{ cursor: 'pointer' }}>{showMore ? 'Read less' : 'Read more'}</button>}
                        </div>} */}
                        {!!startup.description && <div className='small' style={{ lineHeight: '1.33' }}>{startup.description}</div>}
                        {!!startup.linkUrl && <div className='mt-3'>
                            <a href={startup.linkUrl} target='_blank' rel='noopener noreferrer'>
                                {t('visitWebsite')}
                                <ExternalLinkIcon style={{ height: '1rem', verticalAlign: 'top', marginTop: '0.25rem' }} />
                            </a>
                        </div>}
                    </Modal.Body>
                    {!!startup.contactName && <div className='d-flex p-3 bg-primary text-white' style={{ lineHeight: 1.25 }}>
                            {!!startup.contactImageUrl && <div style={{ width: '3rem' }}>
                                <img src={imgBaseUrl + '/t_scale_150/' + startup.contactImageUrl} style={{ width: '2.5rem', height: '2.5rem', borderRadius: '1.25rem' }} alt='' />
                            </div>}
                            <div className='flex-grow-1'>
                                <div>{startup.contactName}</div>
                                <div className='small'><a href={'mailto:' + startup.contactEmail} className='text-white'>{startup.contactEmail}</a></div>
                            </div>
                        </div>}
                    {error && <Alert variant='warning' style={{ margin: 0, borderRadius: 0 }}>{error.message}</Alert>}
                    {/* <Button variant={connected ? 'secondary' : 'primary'} style={{ padding: '1rem', borderRadius: 0 }} onClick={handleConnect}>
                        {connected ? (
                            <>
                                <CircleCheckIcon style={{ marginRight: '0.5rem' }} />
                                {t('connectedWithName', { name: startup.name })}
                            </>
                        ) : (
                            <>
                                <MessageSquareIcon style={{ marginRight: '0.5rem' }} />
                                {t('connectWithName', { name: startup.name })}
                            </>
                        )}
                    </Button> */}
                    {[guest?.field1, guest?.field2].indexOf('Presenter') === -1 && <StartupRating featureId={featureId} startup={startup} key={startup.id} connected={connected} onConnect={handleConnect} />}
                    {(prev || next) && <Modal.Footer className='p-1' style={{ background: '#e5e5e5' }}>
                        <div className='flex-grow-1 text-start'>
                            <Button variant='light pe-4' disabled={!prev} onClick={prev} style={{ opacity: prev ? 1 : 0.25 }}><LeftIcon style={{ height: '1.5rem', verticalAlign: 'top' }} /> {t('prev')}</Button>
                        </div>
                        <div className='flex-grow-1 text-end'>
                            <Button variant='light ps-4' disabled={!next} onClick={next} style={{ opacity: next ? 1 : 0.25 }}>{t('next')} <RightIcon style={{ height: '1.5rem', verticalAlign: 'top' }} /></Button>
                        </div>
                    </Modal.Footer>}
                </>
            )}
        </Modal>
    )
}

export default StartupView