import { Calendar, Users, Award, Zap } from 'react-feather'

import Segment from '../features/segment'
import Speaker from '../features/speaker'
import Startup from '../features/startup'
import Sponsor from '../features/sponsor'

const featureList = [
    {
        id: 'segment',
        url: '/feature/segment',
        icon: Calendar,
        component: Segment
    },
    {
        id: 'speaker',
        url: '/feature/speaker',
        icon: Users,
        component: Speaker
    },
    {
        id: 'startup',
        url: '/feature/startup',
        icon: Award,
        component: Startup
    },
    {
        id: 'sponsor',
        url: '/feature/sponsor',
        icon: Zap,
        component: Sponsor
    }
]

export default featureList