import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Dropdown } from 'react-bootstrap'
import InfoModal from './modals/info'
import SettingsModal from './modals/settings'
// import HelpModal from './modals/help'
import LeaveModal from './modals/leave'
import { Menu as MenuIcon } from 'react-feather'
import style from './style.module.css'

const Menu = () => {

    const { t } = useTranslation()
    const [modal, setModal] = useState()

    return (
        <>
            <Dropdown>
                <Dropdown.Toggle variant='light' id='menuDropdown' className={style.menuToggle}>
                    <MenuIcon />
                </Dropdown.Toggle>
                <Dropdown.Menu className={style.menu + ' dropdown-menu-end'}>
                    <Dropdown.Item onClick={() => setModal('info')}>{t('eventInfo')}</Dropdown.Item>
                    <Dropdown.Item onClick={() => setModal('settings')}>{t('settings')}</Dropdown.Item>
                    {/* <Dropdown.Item onClick={() => setModal('help')}>{t('help')}</Dropdown.Item> */}
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={() => setModal('leave')}>{t('leave')}</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            <InfoModal isOpen={modal === 'info'} onClose={() => setModal()} />
            <SettingsModal isOpen={modal === 'settings'} onClose={() => setModal()} />
            {/* <HelpModal isOpen={modal === 'help'} onClose={() => setModal()} /> */}
            <LeaveModal isOpen={modal === 'leave'} onClose={() => setModal()} />
        </>
    )

}

export default Menu