import { useSelector } from 'react-redux'

const useEvent = () => {

    const event = useSelector(state => state.event)
    
    return event

}

export default useEvent