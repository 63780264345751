import { useSelector } from 'react-redux'
import api from 'lib/api'

const useAPI = () => {
    const token = useSelector(state => state.guest?.token)
    return {
        get: (url, options) => api.get(url, {
            ...options,
            headers: {
                // ...options.headers,
                Authorization: 'Bearer ' + token
            }
        }),
        post: (url, body, options) => api.post(url, body, {
            ...options,
            headers: {
                // ...options.headers,
                Authorization: 'Bearer ' + token
            }
        }),
        put: (url, body, options) => api.put(url, body, {
            ...options,
            headers: {
                // ...options.headers,
                Authorization: 'Bearer ' + token
            }
        }),
        delete: (url, options) => api.delete(url, {
            ...options,
            headers: {
                // ...options.headers,
                Authorization: 'Bearer ' + token
            }
        })
    }
}

export default useAPI