import { Form } from 'react-bootstrap'
import { useField } from 'formik'

const FormikField = props => {
    const [field, meta] = useField(props) // , helpers
    return (
        <div className='form-group'>
            {props.label && <Form.Label>{props.label}</Form.Label>}
            {props.type === 'select' ? (
                <Form.Control as='select' className='form-select' {...field} {...props} isInvalid={!!meta.error}>
                    {props.children}
                </Form.Control>
            ) : (
                <Form.Control {...field} {...props} isInvalid={!!meta.error} />
            )}
            {meta.error && (
              <Form.Control.Feedback type='invalid'>
                {meta.error}
              </Form.Control.Feedback>
            )}
        </div>
    )
}

export default FormikField