import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { format, isSameDay } from 'date-fns'
import { Modal } from 'react-bootstrap'
import { ExternalLink as ExternalLinkIcon } from 'react-feather'
import { imgBaseUrl } from 'lib/cloudinary'
import PoweredBy from 'components/brand/powered-by'
import style from '../style.module.css'

const InfoModal = ({ isOpen, onClose }) => {

    const { t } = useTranslation()
    const event = useSelector(state => state.event)

    const startEnd = useMemo(() => {
        const sDate = new Date(event.startsAt)
        const eDate = new Date(event.endsAt)
        const sameDay = isSameDay(sDate, eDate)
        if (sameDay) {
            return format(sDate, 'PP p') + ' - ' + format(eDate, 'p') // + ' (' + Intl.DateTimeFormat().resolvedOptions().timeZone + ')'
        } else {
            return format(sDate, 'PP p') + ' - ' + format(eDate, 'PP p') // + ' (' + Intl.DateTimeFormat().resolvedOptions().timeZone + ')'
        }
    }, [event])

    return (
        <Modal show={isOpen} onHide={onClose} centered>
            <Modal.Header closeButton onHide={onClose}>{t('eventInfo')}</Modal.Header>
            <Modal.Body>
                {!!event.logoUrl ? (
                    <img src={imgBaseUrl + '/t_scale_300/' + event.logoUrl} alt={event.name} title={event.name} className={style.infoLogo} />
                ) : (
                    <h3>{event.name}</h3>
                )}
                <p className='small text-muted'>
                    {event.hostedBy && <>{t('hostedByName', { name: event.hostedBy })}<br /></>}
                    {event.venuName && <>{event.venueName}<br /></>}
                    {startEnd}
                </p>
                <p className='small mb-2'>{event.description}</p>
                {!!event.website && (
                    <p className='mb-2'>
                        <a href={event.websiteUrl} target='_blank' rel='noopener noreferrer noindex nofollow'>
                            {t('visitWebsite')}
                            <ExternalLinkIcon style={{ height: '1rem', verticalAlign: 'top', marginTop: '0.25rem' }} />
                        </a>
                    </p>
                )}
            </Modal.Body>
            <Modal.Footer>
                <PoweredBy />
            </Modal.Footer>
        </Modal>
    )

}

export default InfoModal