import { useState, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { Container, Card, Row, Col, Alert, Button } from 'react-bootstrap'
import { imgBaseUrl } from 'lib/cloudinary'
import Loading from 'components/loading'
import AreaImg from 'components/area-img'
import StartupView from './view'
import useSWR from 'swr'
import axios from 'axios'
import { useTranslation } from 'react-i18next'

const StartupFeature = ({ feature }) => {

    const [event, token] = useSelector(state => [state.event, state.guest?.token])
    const eventId = event?.id
    const featureId = feature?.id
    // const [selected, setSelected] = useState()
    const [filter, setFilter] = useState()
    const navigate = useNavigate()
    const params = useParams()
    const categories = event?.categories?.filter(c => c.featureId === featureId)
    const { t } = useTranslation()

    const { data: entities, error } = useSWR(
        process.env.REACT_APP_API_URL + '/client/event/' + eventId + '/startup',
        url => axios.get(url, { headers: { authorization: 'Bearer ' + token } }).then(res => res.data),
        { refreshInterval: 5 * 1000 }
    )

    const startups = useMemo(() => {
        if (entities && categories?.length > 0) {
            let newList = []
            for (let i = 0, categoryId; i < categories.length; i++) {
                categoryId = categories[i].id
                newList = newList.concat(entities.filter(e => e.categoryId === categoryId))
            }
            return newList
        } else {
            return entities
        }
    }, [entities, categories])

    const entityIndex = startups && params?.entityId && startups.findIndex(s => s.id === params.entityId)
    const startup = entityIndex > -1 && startups[entityIndex]
    const toggle = () => navigate(feature.url + (startup?.categoryId ? '/category/' + startup.categoryId : ''))
    const prev = startup && entityIndex > 0 ? () => {
        const prevStartup = startups[entityIndex - 1]
        navigate(feature.url + '/entity/' + prevStartup?.id)
     } : null
    const next = startup && entityIndex < startups?.length - 1 ? () => {
        const nextStartup = startups[entityIndex + 1]
        navigate(feature.url + '/entity/' + nextStartup?.id)
     } : null

    useEffect(() => {
        if (categories?.length > 0) {
            if (params.categoryId && filter !== params.categoryId) {
                setFilter(params.categoryId)
            } else if (startup && startup.categoryId && filter !== startup.categoryId) {
                setFilter(startup.categoryId)
            } else if (!filter) {
                setFilter(categories[0].id)
                navigate(feature.url + '/category/' + categories[0].id, { replace: true })
            }
        }
    }, [startup, params])

    return (
        <Container className='py-4'>
            {error ? (
                <Alert>{error.message}</Alert>
            ) : !startups ? (
                <Loading />
            ) : (
                <>
                    {categories?.length > 0 && (
                        <ul className='category-list'>
                            {categories.map((category, index) => (
                                <li key={index}><Button style={{ width: '100%' }} variant={filter === category.id ? 'primary' : 'secondary'} onClick={() => navigate(feature.url + '/category/' + category.id)}>{category.name}</Button></li>
                            ))}
                        </ul>
                    )}
                    <Row xs={2} md={3} lg={4} xl={5} className='g-3 mb-4'>
                        {startups.filter(startup => !filter || startup.categoryId === filter).map(startup => (
                            <Col key={startup.id} style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', justifyContent: 'stretch' }}>
                                <Card style={{ cursor: 'pointer', flex: 1, minHeight: '8rem', overflow: 'hidden' }} onClick={() => navigate(feature.url + '/entity/' + startup.id)}>
                                    {/* () => setSelected(startup) */}
                                    <Card.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', overflow: 'hidden' }}>
                                        {/* {!!startup.imageUrl && <img src={startup.imageUrl} alt={startup.name} style={{ maxHeight: '4.5rem', maxWidth: '100%' }} />} */}
                                        {!!startup.imageUrl && <AreaImg src={imgBaseUrl + '/t_scale_300/' + startup.imageUrl} alt={startup.name} area={'20rem'} />}
                                        {!!startup.badge && (
                                            <div style={{ position: 'absolute', bottom: 0, left: 0, right: 0, background: event.primaryColor, color: '#fff', textAlign: 'center', fontSize: '0.75rem', padding: '0.125rem', fontWeight: 'bold' }}>{t('highestRated')}</div>
                                        )}
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                    <StartupView featureId={featureId} startup={startup} toggle={toggle} prev={prev} next={next} />
                    {/* () => setSelected()} /> */}
                </>
            )}
        </Container>
    )
}

export default StartupFeature