export const LOAD_EVENT = 'engage/event/LOAD'
export const UPDATE_EVENT = 'engage/event/UPDATE'
export const CREATE_FEATURE = 'engage/feature/CREATE'
export const UPDATE_FEATURE = 'engage/feature/UPDATE'
export const DELETE_FEATURE = 'engage/feature/DELETE'
export const REORDER_FEATURES = 'engage/feature/REORDER'
export const CREATE_CATEGORY = 'engage/category/CREATE'
export const UPDATE_CATEGORY = 'engage/category/UPDATE'
export const DELETE_CATEGORY = 'engage/category/DELETE'
export const REORDER_CATEGORIES = 'engage/category/REORDER'
export const CREATE_ENTITY = 'engage/entity/CREATE'
export const UPDATE_ENTITY = 'engage/entity/UPDATE'
export const DELETE_ENTITY = 'engage/entity/DELETE'
export const REORDER_ENTITIES = 'engage/entity/REORDER'
export const CLEAR_EVENT = 'engage/event/CLEAR'

const initialState = () => Object.create(null)

const sort = (items, order) => {
    const itemsById = {}
    items.forEach((item) => {
        itemsById[item.id] = item
    })
    return order.map((item) => ({
        ...itemsById[item.id],
        ...item
    }))
}

const reducer = (state = initialState(), { type, data }) => {
    switch (type) {
        case LOAD_EVENT:
            return { ...data }
        case UPDATE_EVENT:
            return { ...state, ...data }
        case CREATE_FEATURE:
            return {
                ...state,
                features: [
                    data,
                    ...state.features
                ]
            }
        case UPDATE_FEATURE:
            return {
                ...state,
                features: state.features.map((feature) => feature.id === data.id ? ({
                    ...feature,
                    ...data
                }) : feature)
            }
        case DELETE_FEATURE:
            return {
                ...state,
                features: state.features.filter((feature) => feature.id !== data.id)
            }
        case REORDER_FEATURES:
            return {
                ...state,
                features: sort(state.features, data)
            }
        case CREATE_CATEGORY:
            return {
                ...state,
                categories: [
                    data,
                    ...state.categories
                ]
            }
        
        case UPDATE_CATEGORY:
            return {
                ...state,
                categories: state.categories.map((category) => category.id === data.id ? ({
                    ...category,
                    ...data
                }) : category)
            }
        case DELETE_CATEGORY:
            return {
                ...state,
                categories: state.categories.filter((category) => category.id !== data.id)
            }
        case REORDER_CATEGORIES:
            return {
                ...state,
                categories: sort(state.categories, data)
            }
        case CREATE_ENTITY:
            return {
                ...state,
                entities: [
                    data,
                    ...state.entities
                ]
            }
        case UPDATE_ENTITY:
            return {
                ...state,
                entities: state.entities.map((entity) => entity.id === data.id ? ({
                    ...entity,
                    ...data
                }) : entity)
            }
        case DELETE_ENTITY:
            return {
                ...state,
                entities: state.entities.filter((entity) => entity.id !== data.id)
            }
        case REORDER_ENTITIES:
            return {
                ...state,
                entities: sort(state.entities, data)
            }
        case CLEAR_EVENT:
            return initialState()
        default:
            return state
    }
}

export default reducer

export const loadEvent = (data) => {
    return {
        type: LOAD_EVENT,
        data
    }
}

export const updateEvent = (data) => {
    return {
        type: UPDATE_EVENT,
        data
    }
}

export const createFeature = (data) => {
    return {
        type: CREATE_FEATURE,
        data
    }
}

export const updateFeature = (data) => {
    return {
        type: UPDATE_FEATURE,
        data
    }
}

export const deleteFeature = (data) => {
    return {
        type: DELETE_FEATURE,
        data
    }
}

export const reorderFeatures = (data) => {
    return {
        type: REORDER_FEATURES,
        data
    }
}

export const createCategory = (data) => {
    return {
        type: CREATE_CATEGORY,
        data
    }
}

export const updateCategory = (data) => {
    return {
        type: UPDATE_CATEGORY,
        data
    }
}

export const deleteCategory = (data) => {
    return {
        type: DELETE_CATEGORY,
        data
    }
}

export const reorderCategories = (data) => {
    return {
        type: REORDER_CATEGORIES,
        data
    }
}

export const createEntity = (data) => {
    return {
        type: CREATE_ENTITY,
        data
    }
}

export const updateEntity = (data) => {
    return {
        type: UPDATE_ENTITY,
        data
    }
}

export const deleteEntity = (data) => {
    return {
        type: DELETE_ENTITY,
        data
    }
}

export const reorderEntities = (data) => {
    return {
        type: REORDER_ENTITIES,
        data
    }
}

export const clearEvent = () => {
    return {
        type: CLEAR_EVENT
    }
}