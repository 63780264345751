import { Link } from 'react-router-dom'
import { imgBaseUrl } from 'lib/cloudinary'
import style from './style.module.css'
import Menu from './menu'

const Header = ({ event }) => {
  
    return (
        <div className={style.header}>
          <Link to='/' className={style.headerBrand}>
            {!!event.logoUrl ? (
              <img src={imgBaseUrl + '/t_scale_150/' + event.logoUrl} alt={event.name} title={event.name} className={style.headerLogo} />
            ) : (
              <div className={style.brandText}>{event.name}</div>
            )}
          </Link>
          <div className={style.headerSpacer} />
          <ul className={style.headerNav}>
            <li className={style.headerNavItem}>
              <Menu />
            </li>
          </ul>
        </div>
    )

}

export default Header