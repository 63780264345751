import { useState, useEffect, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ListGroup, Alert, Button, Form } from 'react-bootstrap'
import { useFormik } from 'formik'
import * as yup from 'yup'
import useActions from 'hooks/use-actions'
import { ReactComponent as Star } from './star-solid.svg'

const ratingSchema = yup.object().shape({
    rating: yup.number().min(1).max(5).required(),
    comments: yup.string().nullable().optional()
})

const StartupVote = ({ featureId, startup, connected, onConnect }) => {

    const { actions, doAction } = useActions(featureId, startup?.id)
    const [submitted, setSubmitted] = useState(false)
    const [error, setError] = useState(null)

    useEffect(() => {
        if (!startup?.id) return
        setSubmitted(actions.find(action => action.type === 'vote' && action.entityId === startup?.id))
    }, []) // startup, actions

    const submitRating = useCallback(values => {
        if (!startup) return false
        doAction('vote', startup.id, values)
        .then(action => {
            setError()
            setSubmitted(action)
        })
        .catch(setError)
    }, [doAction, startup])

    const initialValues = {
        rating: null,
        comments: ''
    }

    const { t } = useTranslation()
    const { values, errors, setFieldValue, handleSubmit } = useFormik({
        initialValues,
        validationSchema: ratingSchema,
        // validate: submitRating,
        onSubmit: submitRating
    })

    const handleReset = useCallback(() => {
        if (submitted) {
            setFieldValue('rating', submitted.data?.rating || null)
            setFieldValue('comments', submitted.data?.comments)
        }
        setSubmitted(false)
    }, [setFieldValue, submitted])

    const results = startup?.data
    
    return results ? (
        <div className='p-3 text-light' style={{ background: '#333' }}>
            <h5 className='mb-2'>{t('averageRating')}</h5>
            <ListGroup>
                <ListGroup.Item className='text-center flex-grow-1 bg-dark'>
                    {/* <div className='text-muted'>{t('relevance')}</div> */}
                    {/* <div className='h5 m-0'>{results.rating}</div> */}
                    <Stars rating={results.rating} size='1.75rem' />
                </ListGroup.Item>
            </ListGroup>
        </div>
    ) : submitted ? (
        <div className='p-3 text-light' style={{ background: '#333' }}>
            <div>
                <h5 className='mb-2 d-inline-block'>{t('yourRating')}</h5>
                <Button variant='link' size='sm' className='mt-n1 text-info' onClick={handleReset}>{t('changeRating')}</Button>
            </div>
            <ListGroup>
                <ListGroup.Item className='flex-grow-1 bg-dark p-3'>
                    {/* <div className='display-3 m-0'>{submitted.data?.rating}</div> */}
                    <Stars rating={submitted.data?.rating} size='1.75rem' />
                </ListGroup.Item>
                {submitted.data?.comments && (
                    <ListGroup.Item className='flex-grow-1 bg-dark text-white p-4 pb-3 pt-0'>
                        {/* <div className='text-muted small mb-1'>{t('commentsAndQuestions')}</div> */}
                        {submitted.data?.comments}
                    </ListGroup.Item>
                )}
            </ListGroup>
        </div>
    ) : (
        <form onSubmit={handleSubmit} noValidate>
            <div className='p-3 text-light' style={{ background: '#333' }}>
                <div className='mb-2'>
                    <h4>{t('rating')}</h4>
                    <div className='mb-1 small'>{t('ratingStarLabel')}</div>
                    <ListGroup horizontal>
                        {/* variant={values.rating === 1 ? 'primary' : null} */}
                        <ListGroup.Item className='p-0 flex-grow-1'>
                            <label className='py-2 lh-0 text-center h5 m-0 d-block' style={{ cursor: 'pointer', color: values.rating >= 1 ? 'orange' : '#e5e5e5' }}>
                                <Star style={{ height: '1.75rem', width: '1.75rem', fill: 'currentcolor' }} />
                                <input type='radio' name='rating' value='1' className='d-none' onChange={() => setFieldValue('rating', 1)} />
                            </label>
                        </ListGroup.Item>
                        <ListGroup.Item className='p-0 flex-grow-1'>
                            <label className='py-2 lh-0 text-center h5 m-0 d-block' style={{ cursor: 'pointer', color: values.rating >= 2 ? 'orange' : '#e5e5e5' }}>
                                <Star style={{ height: '1.75rem', width: '1.75rem', fill: 'currentcolor' }} />
                                <input type='radio' name='rating' value='2' className='d-none' onChange={() => setFieldValue('rating', 2)} />
                            </label>
                        </ListGroup.Item>
                        <ListGroup.Item className='p-0 flex-grow-1'>
                            <label className='py-2 lh-0 text-center h5 m-0 d-block' style={{ cursor: 'pointer', color: values.rating >= 3 ? 'orange' : '#e5e5e5' }}>
                                <Star style={{ height: '1.75rem', width: '1.75rem', fill: 'currentcolor' }} />
                                <input type='radio' name='rating' value='3' className='d-none' onChange={() => setFieldValue('rating', 3)} />
                            </label>
                        </ListGroup.Item>
                        <ListGroup.Item className='p-0 flex-grow-1'>
                            <label className='py-2 lh-0 text-center h5 m-0 d-block' style={{ cursor: 'pointer', color: values.rating >= 4 ? 'orange' : '#e5e5e5' }}>
                                <Star style={{ height: '1.75rem', width: '1.75rem', fill: 'currentcolor' }} />
                                <input type='radio' name='rating' value='4' className='d-none' onChange={() => setFieldValue('rating', 4)} />
                            </label>
                        </ListGroup.Item>
                        <ListGroup.Item className='p-0 flex-grow-1'>
                            <label className='py-2 lh-0 text-center h5 m-0 d-block' style={{ cursor: 'pointer', color: values.rating >= 5 ? 'orange' : '#e5e5e5' }}>
                                <Star style={{ height: '1.75rem', width: '1.75rem', fill: 'currentcolor' }} />
                                <input type='radio' name='rating' value='5' className='d-none' onChange={() => setFieldValue('rating', 5)} />
                            </label>
                        </ListGroup.Item>
                    </ListGroup>
                    {errors?.rating && <div className='text-danger small mb-n2'>{t('required')}</div>}
                </div>
                <div className='mb-2'>
                    <div className='mb-1 small'>{t('ratingCommentLabel')}</div>
                    <Form.Control as='textarea' style={{ resize: 'none' }} rows={3} defaultValue={values.comments} onChange={e => setFieldValue('comments', e.target.value)} />
                </div>
                {/* <div className='mb-2'>
                    <Form.Check type='checkbox' label={'Share my contact info with this presenter'} id={'startupRatingConnect'} checked={connected} onChange={onConnect} />
                </div> */}
                {error && <Alert variant='warning'>{error.message}</Alert>}
                <div>
                    <Button type='submit' size='lg' variant={submitted ? 'secondary' : 'primary'} className='mt-2' style={{ cursor: submitted ? 'default' : 'pointer' }} disabled={!values.rating}>
                        {t('submit')}
                    </Button>
                </div>
            </div>
        </form>
    )

}

export default StartupVote



    // const results = useMemo(() => {
    //     const uniqueVotes = []
    //     let resultCount = 0
    //     let qualityScore = 0
    //     let relevanceScore = 0
    //     actions.filter(action => {
    //         if (action.entity !== startup.id) return false
    //         if (uniqueVotes.indexOf(action.guestId) > -1) return false
    //         uniqueVotes.push(action.guestId)
    //         return true
    //     }).forEach(action => {
    //         resultCount++
    //         qualityScore += Number(action.data.quality)
    //         relevanceScore += Number(action.data.relevance)
    //     })
    //     return {
    //         quality: qualityScore / resultCount,
    //         relevance: relevanceScore / resultCount
    //     }
    // }, [submitted, actions])

    const Stars = ({ rating, size }) => {
        const value = Math.round(rating)
        return (
            <div className='d-flex justify-content-start'>
                <div className='px-1' style={{ color: value >= 1 ? 'orange' : '#333' }}>
                    <Star style={{ height: size, width: size, fill: 'currentcolor' }} />
                </div>
                <div className='px-1' style={{ color: value >= 2 ? 'orange' : '#333' }}>
                    <Star style={{ height: size, width: size, fill: 'currentcolor' }} />
                </div>
                <div className='px-1' style={{ color: value >= 3 ? 'orange' : '#333' }}>
                    <Star style={{ height: size, width: size, fill: 'currentcolor' }} />
                </div>
                <div className='px-1' style={{ color: value >= 4 ? 'orange' : '#333' }}>
                    <Star style={{ height: size, width: size, fill: 'currentcolor' }} />
                </div>
                <div className='px-1' style={{ color: value >= 5 ? 'orange' : '#333' }}>
                    <Star style={{ height: size, width: size, fill: 'currentcolor' }} />
                </div>
            </div>
        )
    }