import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Modal, Button } from 'react-bootstrap'
import { clearEvent } from 'store/event'
import { clearGuest } from 'store/guest'
import api from 'lib/api'

const LeaveModal = ({ isOpen, onClose }) => {

    const { t } = useTranslation()
    const { eventId, user } = useSelector(state => ({ eventId: state.event?.id, user: state.guest }))
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleLeave = () => {
        api.post('/event/' + eventId + '/leave', null, {
            headers: {
                authorization: 'Bearer ' + user.token
            }
        })
        .catch(console.error)
        .finally(() => {
            dispatch(clearEvent())
            dispatch(clearGuest())
            // history.replace('/')
            navigate('/')
        })
    }

    return (
        <Modal show={isOpen} onHide={onClose} centered>
            <Modal.Header closeButton onHide={onClose}>{t('leave')}</Modal.Header>
            <Modal.Body>
                <p>{t('leavePrompt')}</p>
                <Button variant='danger' size='lg' onClick={handleLeave}>{t('leave')}</Button>
                <Button variant='link' size='lg' onClick={onClose}>{t('cancel')}</Button>
            </Modal.Body>
            {/* <Modal.Footer>
            </Modal.Footer> */}
        </Modal>
    )

}

export default LeaveModal