import axios from 'axios'

const apiURL = process.env.REACT_APP_API_URL + '/client'

const apiRequest = (method, url, data, options) => {

    if (['get', 'put', 'post', 'patch', 'delete'].indexOf(method) === -1) throw new Error('Unsupported API method')
    // if (options) throw new Error('Options not yet supported')

    return axios({
        method,
        url: apiURL + url,
        data: method !== 'get' ? data : undefined,
        params: method === 'get' ? data : undefined,
        ...options
    })
    .then(response => {
        return Promise.resolve(response.data)
    })
    .catch(error => {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            // console.log(error.response.data)
            // console.log(error.response.status)
            // console.log(error.response.headers)
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            // console.log(error.request)
        } else {
            // Something happened in setting up the request that triggered an Error
            // console.log('Error', error.message)
        }
        // console.log(error.config)
        return Promise.reject(new Error(error.message))
    })

}

const apiGet = (url, options) => apiRequest('get', url, null, options)
const apiPut = (url, body, options) => apiRequest('put', url, body, options)
const apiPost = (url, body, options) => apiRequest('post', url, body, options)
const apiDelete = (url, body, options) => apiRequest('delete', url, body, options)

const api = {
    get: apiGet,
    put: apiPut,
    post: apiPost,
    delete: apiDelete
}

export default api