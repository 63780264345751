import { Suspense } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider} from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import Loading from 'components/loading'
import { store, persistor } from './store'
import './style/index.scss'

import Gate from 'components/gate'
import Event from 'components/event'

const App = () => {
  
  return (
    <Suspense fallback={<Loading />}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            <Gate>
              <Event />
            </Gate>
          </Router>
        </PersistGate>
      </Provider>
    </Suspense>
  )
  
}

export default App