import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Container, Card, Alert } from 'react-bootstrap'
import Loading from '../../components/loading'
import useSWR from 'swr'
import axios from 'axios'
import { ChevronRight } from 'react-feather'

const SegmentFeature = ({ feature }) => {

    const [event, token] = useSelector(state => [state.event, state.guest?.token])
    const eventId = event?.id
    // const featureId = feature?.id
    
    const { data: segments, error } = useSWR(
        process.env.REACT_APP_API_URL + '/client/event/' + eventId + '/segment',
        url => axios.get(url, { headers: { authorization: 'Bearer ' + token } }).then(res => res.data),
        { refreshInterval: 5 * 1000 }
    )

    return (
        <Container className='py-4'>
            {error ? (
                <Alert>{error.message}</Alert>
            ) : !segments ? (
                <Loading />
            ) : (
                <div className='mb-4'>
                    {/* <ListGroup className='list-group-flush'> */}
                    {segments.map(segment => {
                        const link = segment.linkType && segment.linkFeature && '/feature/' + segment.linkFeature + (segment.linkType === 'category' ? '/category/' + segment.linkTarget : segment.linkType === 'entity' ? '/entity/' + segment.linkTarget : '')
                        return (
                            <Card key={segment.id} as={link ? Link : Card} to={link} className={'p-0 d-flex mb-1 flex-row align-items-center'} style={segment.badge === 'current' ? { background: event.primaryColor, color: '#fff' } : { background: '#fff', color: '#000' }}>
                                {/* <div style={{ background: '#cfe2ff', width: '0.5rem', position: 'relative' }}>
                                    <div style={{ position: 'absolute', top: 0, left: 0, right: 0, height: percent + '%', background: 'dodgerblue' }} />
                                </div> */}
                                {/* {!!segment.imageUrl && <img src={segment.imageUrl} alt={segment.name} />} */}
                                <div style={{ flex: 1 }} className='px-2 py-3'>
                                    <div className='h6 mb-0'>{segment.name}</div>
                                    {!!segment.detail && <div className='small' style={{ lineHeight: 1.25, opacity: 0.8 }}>{segment.detail}</div>}
                                    {/* {!!segment.startsAt && !!segment.endsAt && <div className='text-muted'>{new Date(segment.startsAt).toLocaleTimeString()} - {new Date(segment.endsAt).toLocaleTimeString()}</div>} */}
                                </div>
                                {/* <div className='d-flex align-items-center small p-2'>
                                    {segment.duration} minutes
                                </div> */}
                                <div className='px-2'>
                                    {!!link && <ChevronRight style={{ opacity: 0.5, color: segment.badge === 'current' ? '#fff' : '#000' }} />}
                                </div>
                                </Card>
                            // </ListGroup.Item>
                        )
                    })}
                </div>
                // </ListGroup></Card>
            )}
        </Container>
    )
}

export default SegmentFeature