import { useState, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Container, Card, Row, Col, Alert, Button } from 'react-bootstrap'
import { imgBaseUrl } from 'lib/cloudinary'
import Loading from 'components/loading'
import AreaImg from 'components/area-img'
import SponsorView from './view'
import useSWR from 'swr'
import axios from 'axios'

const SponsorFeature = ({ feature }) => {

    const [event, token] = useSelector(state => [state.event, state.guest?.token])
    const eventId = event?.id
    const featureId = feature?.id
    const [selected, setSelected] = useState()
    const [filter, setFilter] = useState()
    const categories = event?.categories?.filter(c => c.featureId === featureId)

    const { data: entities, error } = useSWR(
        process.env.REACT_APP_API_URL + '/client/event/' + eventId + '/sponsor',
        url => axios.get(url, { headers: { authorization: 'Bearer ' + token } }).then(res => res.data),
        { refreshInterval: 60 * 1000 }
    )

    const sponsors = useMemo(() => {
        if (entities && categories?.length > 0) {
            let newList = []
            let categoryId
            for (let i = 0; i < categories.length; i++) {
                categoryId = categories[i].id
                newList = newList.concat(entities.filter(e => e.categoryId === categoryId))
            }
            return newList
        } else {
            return entities
        }
    }, [entities, categories])

    useEffect(() => {
        if (categories.length > 0 && !filter) setFilter(categories[0].id)
    }, [])

    return (
        <Container className='py-4'>
            {error ? (
                <Alert>{error.message}</Alert>
            ) : !sponsors ? (
                <Loading />
            ) : (
                <>
                    {categories?.length > 0 && (
                        <ul className='category-list'>
                            {categories.map((category, index) => (
                                <li key={index}><Button style={{ width: '100%' }} variant={filter === category.id ? 'primary' : 'secondary'} onClick={() => setFilter(category.id)}>{category.name}</Button></li>
                            ))}
                        </ul>
                    )}
                    <Row xs={2} md={3} lg={4} xl={5} className='g-3 mb-4'>
                        {sponsors.filter(sponsor => !filter || sponsor.categoryId === filter).map(sponsor => (
                            <Col key={sponsor.id} style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', justifyContent: 'stretch' }}>
                                <Card style={{ cursor: 'pointer', flex: 1, minHeight: '8rem' }} onClick={() => setSelected(sponsor)}>
                                    <Card.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', overflow: 'hidden' }}>
                                        {/* {!!sponsor.imageUrl && <img src={sponsor.imageUrl} alt={sponsor.name} style={{ maxHeight: '4.5rem', maxWidth: '100%' }} />} */}
                                        {!!sponsor.imageUrl && <AreaImg src={imgBaseUrl + '/t_scale_300/' + sponsor.imageUrl} alt={sponsor.name} area={'20rem'} />}
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                    <SponsorView sponsor={selected} toggle={() => setSelected()} />
                </>
            )}
        </Container>
    )
}

export default SponsorFeature