export const LOAD_GUEST = 'engage/guest/LOAD'
export const CLEAR_GUEST = 'engage/guest/CLEAR'

const initialState = () => Object.create(null)

const reducer = (state = initialState(), { type, data }) => {
    switch (type) {
        case LOAD_GUEST:
            return { ...data }
        case CLEAR_GUEST:
            return initialState()
        default:
            return state
    }
}

export default reducer

export const loadGuest = guestData => {
    return {
        type: LOAD_GUEST,
        data: guestData
    }
}

export const clearGuest = () => {
    return {
        type: CLEAR_GUEST
    }
}