import { useState, useEffect } from 'react'
import {
  Routes,
  Route,
  Navigate,
  Link
} from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useSelector, useDispatch } from 'react-redux'
import ErrorBoundary from 'components/error-boundary'
import PresentationMode from 'components/presentation-mode'
import useFeatures from 'hooks/use-features'
import useEvent from 'hooks/use-event'
import Layout from 'components/layout'
import { io } from 'socket.io-client'
import { Toast } from 'react-bootstrap' // , Modal, Form, Button, ListGroup, Spinner, Alert
import style from './style.module.css'
import { updateEvent, createFeature, updateFeature, deleteFeature, reorderFeatures, createCategory, updateCategory, deleteCategory, reorderCategories, createEntity, updateEntity, deleteEntity, reorderEntities } from 'store/event'
import { loadActions } from 'store/action'
import useAPI from 'hooks/use-api'

const Event = () => {

  const event = useEvent()
  const token = useSelector(state => state.guest?.token)
  const features = useFeatures(event?.features)
  const [toasts, setToasts] = useState([])
  // const [prompts, setPrompts] = useState([])
  const dispatch = useDispatch()
  const api = useAPI()

  useEffect(() => {
    const socket = io(process.env.REACT_APP_SOCKET_URL, {
      path: '/io/',
      auth: {
        token
      }
    })
    socket.on('connect', () => {
      console.log('Socket connected', socket.id)
    })
    socket.on('disconect', () => {
      console.log('Socket disconnected', socket.id)
    })
    socket.on('toast', data => {
      console.log('toast', data)
      setToasts(toasts => [...toasts, data])
    })
    socket.on('prompt', data => {
      console.log('prompt', data)
      // setPrompts(prompts => [...prompts, data])
    })
    socket.on('updateEvent', (data) => {
      dispatch(updateEvent(data.event))
    })
    // Feature
    socket.on('createFeature', (data) => {
      dispatch(createFeature(data.feature))
    })
    socket.on('updateFeature', (data) => {
      dispatch(updateFeature(data.feature))
    })
    socket.on('deleteFeature', (data) => {
      dispatch(deleteFeature(data.feature))
    })
    socket.on('reorderFeatures', (data) => {
      dispatch(reorderFeatures(data.features))
    })
    // Category
    socket.on('createCategory', (data) => {
      dispatch(createCategory(data.category))
    })
    socket.on('updateCategory', (data) => {
      dispatch(updateCategory(data.category))
    })
    socket.on('deleteCategory', (data) => {
      dispatch(deleteCategory(data.category))
    })
    socket.on('reorderCategories', (data) => {
      dispatch(reorderCategories(data.categories))
    })
    // Entity
    socket.on('createEntity', (data) => {
      dispatch(createEntity(data.entity))
    })
    socket.on('updateEntity', (data) => {
      dispatch(updateEntity(data.entity))
    })
    socket.on('deleteEntity', (data) => {
      dispatch(deleteEntity(data.entity))
    })
    socket.on('reorderEntities', (data) => {
      dispatch(reorderEntities(data.entities))
    })
    return () => {
      socket.disconnect()
    }
  }, [])

  useEffect(() => {
    if (!event?.id) return
    api.get('/event/' + event.id + '/action')
    .then(actions => {
      dispatch(loadActions(actions))
    })
    .catch(console.error)
    api.get('/event/' + event.id + '/feature')
    .then(features => {
      dispatch(updateEvent({ features }))
    })
    .catch(console.error)
    api.get('/event/' + event.id + '/category')
    .then(categories => {
      dispatch(updateEvent({ categories }))
    })
    .catch(console.error)
    api.get('/event/' + event.id + '/entity')
    .then(entities => {
      dispatch(updateEvent({ entities }))
    })
    .catch(console.error)
  }, [])

  return (
    <>
      <Layout event={event}>
        <Routes>
          <Route exact path='/' element={<FeatureRedirect />} />
          {features.map(feature => (
            <Route key={feature.id} exact path={feature.url} element={
              <ErrorBoundary>
                {feature.component ? <feature.component feature={feature} featureId={feature.id} /> : null}
              </ErrorBoundary>
            } />
          ))}
          {features.map(feature => (
            <Route key={feature.id} exact path={feature.url + '/category/:categoryId'} element={
              <ErrorBoundary>
                {feature.component ? <feature.component feature={feature} featureId={feature.id} /> : null}
              </ErrorBoundary>
            } />
          ))}
          {features.map(feature => (
            <Route key={feature.id} exact path={feature.url + '/entity/:entityId'} element={
              <ErrorBoundary>
                {feature.component ? <feature.component feature={feature} featureId={feature.id} /> : null}
              </ErrorBoundary>
            } />
          ))}
          <Route path='*' render={() => <Navigate to='/' />} />
        </Routes>
      </Layout>
      <PresentationMode />
      <div style={{ position: 'absolute', top: '4rem', right: '0.5rem', zIndex: 1 }}>
        {toasts.map((toast, index) => <ToastItem toast={toast} key={index} onClose={() => setToasts(toasts => toasts.filter((t, i) => i !== index))} />)}
      </div>
      {/* {prompts.map((prompt, index) => <PromptItem prompt={prompt} key={index} onClose={() => setPrompts(prompts => prompts.filter((p, i) => i !== index))} />)} */}
      <Helmet>
          <title>{event.name}</title>
          <meta name='theme-color' content={event.primaryColor} />
      </Helmet>
    </>
  )

}

export default Event

const FeatureRedirect = () => {
  const event = useEvent()
  const features = useFeatures(event?.features)
  if (features && features.length > 0) {
    return <Navigate to={features[0].url} />
  } else {
    return null
  }
}

//  style={{ backgroundColor: event.secondaryColor }} className={style.toastHeader}
const ToastItem = ({ toast, onClose }) => {
  let toastLink
  if (toast.link) {
    const splitLink = toast.link.split(':') // speaker:uuid
    toastLink = '/feature/' + splitLink[0] + '/' + (splitLink[1] || '')
  }
  return (
    <Toast show={true} onClose={onClose} className={style.toast} as={toastLink ? Link : 'div'} to={toastLink}>
      <Toast.Header>
        <div style={{ flex: 1 }}>{toast.title}</div>
      </Toast.Header>
      <Toast.Body>
        {toast.message}
      </Toast.Body>
    </Toast>
  )
}

// const PromptItem = ({ prompt, onClose }) => {
//   const { t } = useTranslation()
//   const { doAction } = useActions('prompt')
//   const [response, setResponse] = useState()
//   const [loading, setLoading] = useState()
//   const [error, setError] = useState()
//   const handleChange = e => setResponse(e.target.value)
//   const handleSubmit = () => {
//     console.log(response)
//     setLoading(true)
//     doAction({
//       promptId: prompt.id,
//       response
//     })
//     .then(() => {
//       setError()
//       onClose()
//     })
//     .catch(error => {
//       setError(error)
//     })
//     .finally(() => {
//       setLoading()
//     })
//   }
//   return (
//     <Modal show={true} onClose={onClose} centered size='sm'>
//       <Modal.Header onClose={onClose}>
//         {prompt.title}
//       </Modal.Header>
//       <Modal.Body>
//         <p>{prompt.question}</p>
//         <Form onSubmit={handleSubmit} noValidate>
//           <ListGroup>
//             {prompt.options.map((option, index) => (
//               <ListGroup.Item key={index}>
//                 <Form.Check type='radio' value={option} label={option} checked={response === option} onChange={handleChange} />
//               </ListGroup.Item>
//             ))}
//           </ListGroup>
//           {error && <Alert variant='warning'>{error.message}</Alert>}
//           <Button variant='primary' type='submit' disabled={!response || loading}>{loading ? <Spinner /> : t('Submit')}</Button>
//           <Button variant='link' type='reset' onClick={onClose}>{t('Cancel')}</Button>
//         </Form>
//       </Modal.Body>
//     </Modal>
//   )
// }