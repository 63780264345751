const Theme = ({ event }) => {

    const { primaryColor, secondaryColor, splashImage } = event

    return (
        <style>{`
        .dropdown-item.active, .dropdown-item:active {
            background-color: ${primaryColor};
        }
        a, .btn-link {
            color: ${primaryColor};
        }
        .text-info, .text-primary {
            color: ${primaryColor} !important;
        }
        .bg-primary {
            background-color: ${primaryColor} !important;
        }
        a:hover, a:focus, a:active, .btn-link:hover, .btn-link:focus, .btn-link:active, .text-secondary {
            color: ${secondaryColor};
        }
        .btn-primary, .btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary:disabled, .btn-primary.disabled, .btn-primary:first-child:hover {
            background-color: ${primaryColor};
            border-color: ${primaryColor};
        }
        .btn-secondary, .btn-secondary:hover, .btn-secondary:focus, .btn-secondary:active, .btn-secondary:disabled, .btn-secondary.disabled, .btn-secondary:first-child:hover {
            background-color: ${secondaryColor};
            border-color: ${secondaryColor};
        }
        .event-content-area {
            background-image: url(${splashImage});
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }
        `}</style>
    )

}

export default Theme

// background: linear-gradient(90deg, ${primaryColor} 0%, ${secondaryColor} 100%);