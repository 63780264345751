import { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, Button, Alert } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { loadGuest } from 'store/guest'
import { Formik } from 'formik'
import Field from 'components/formik/field'
import yup from 'lib/yup'
import useAPI from 'hooks/use-api'

const SettingsModal = ({ isOpen, onClose }) => {

    const { t } = useTranslation()
    const [error, setError] = useState()
    const [loading, setLoading] = useState()
    const { user, event } = useSelector(state => ({ user: state.guest, event: state.event }))
    const dispatch = useDispatch()
    const api = useAPI()

    const handleSave = values => {
        setLoading(true)
        api.put('/guest/' + user.id, values)
        .then(() => {
            setLoading()
            setError()
            dispatch(loadGuest({
                ...user,
                ...values
            }))
            onClose()
        })
        .catch(error => {
            console.error(error)
            setError(error)
            setLoading()
        })
    }

    const settingsSchema = useMemo(() => yup.object().shape({
        name: yup.string().min(2).max(100).required().label(t('name')),
        field1: event?.fields?.length > 0 ?
            event.fields[0].required ?
                yup.string().max(100).required().label(event.fields[0].label)
                : yup.string().max(100).label(event.fields[0].label)
            : yup.string().nullable(),
        field2: event?.fields?.length > 1 ?
            event.fields[1].required ?
                yup.string().max(100).required().label(event.fields[1].label)
                : yup.string().max(100).label(event.fields[1].label)
            : yup.string().nullable()
        // language: yup.string().max(5).required(),
        // currency: yup.string().uppercase().length(3).required()
    }), [event])

    const initialValues = {
        name: user.name || '',
        field1: user.field1 || '',
        field2: user.field2 || ''
        // language: user.language || 'en',
        // currency: user.currency || 'USD'
    }

    return (
        <Modal show={isOpen} onHide={onClose} centered>
            <Formik initialValues={initialValues} validationSchema={settingsSchema} onSubmit={handleSave}>
                {({ handleSubmit, isValid }) => (
                    <form noValidate onSubmit={handleSubmit}>
                        <Modal.Header closeButton onHide={onClose}>{t('settings')}</Modal.Header>
                        <Modal.Body>
                            <Field name='name' type='text' label={t('name')} required autoComplete='off' />
                            {event?.fields?.length > 0 && event.fields.map((field, index) => field.type === 'select' ? (
                                <Field key={index} name={'field' + (index + 1)} label={field.label} type={field.type}>
                                <option />
                                {field.options?.map((option, index) => (
                                    <option key={index} value={typeof option === 'string' ? option : option.value}>{typeof option === 'string' ? option : option.name}</option>
                                ))}
                                </Field>
                            ) : (
                                <Field key={index} name={'field' + (index + 1)} label={field.label} type={field.type} autoComplete='off' />
                            ))}
                            {/* <Field name='language' type='select' label={t('language')}>
                                <option value='en'>English (US)</option>
                            </Field>
                            <Field name='currency' type='select' label={t('currency')}>
                                <option value='USD'>USD</option>
                            </Field> */}
                            {error && <Alert variant='warning'>{error.message}</Alert>}
                            <div className='mt-4'>
                                <Button variant='primary' size='lg' type='submit' disabled={loading || !isValid}>{t('save')}</Button>
                                <Button variant='link' size='lg' onClick={onClose}>{t('cancel')}</Button>
                            </div>
                        </Modal.Body>
                    </form>
                )}
            </Formik>
        </Modal>
    )

}

export default SettingsModal