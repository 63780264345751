import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { addAction, loadActions } from 'store/action'
import useAPI from 'hooks/use-api'
// import useSWR from 'swr'
// import axios from 'axios'

const useActions = featureId => { // entity, type

    const [event, token, actions] = useSelector(state => [
        state.event,
        state.guest?.token,
        state.actions?.all?.filter(action => !featureId || action.featureId === featureId) || []
    ])
    const eventId = event?.id
    const dispatch = useDispatch()
    const api = useAPI()

    // const { data, error } = useSWR(
    //     process.env.REACT_APP_API_URL + '/event/' + eventId + '/action',
    //     url => axios.get(url, { headers: { authorization: 'Bearer ' + token } }).then(res => res.data),
    //     { refreshInterval: 10 * 1000 }
    // )
    // useEffect(() => {
    //     dispatch(loadActions(data))
    // }, [data])
    // const { actions, eventId } = useSelector(state => {
    //     return {
    //         actions: state.actions?.all?.filter(action => !actionType || action.type === actionType),
    //         eventId: state.event?.id,
    //         token:
    //     }
    // })
    
    const doAction = (type, entityId, data) => {
        return api.post('/event/' + eventId + '/action', {
            type,
            featureId,
            entityId,
            data
        })
        .then(action => {
            dispatch(addAction(action))
            return action
        })
    }

    // const findAction = search => {
    //     if (!actions || actions.length === 0 || !search || typeof search !== 'object') return false
    //     const result = actions.find(action => {
    //         let match = true
    //         Object.keys(search).forEach(key => {
    //             if (action.data[key] !== search[key]) match = false
    //         })
    //         return match
    //     })
    //     return result
    // }

    return { actions, doAction } // findAction

}

export default useActions