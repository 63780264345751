import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
// import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap'
// import { MessageSquare as MessageSquareIcon } from 'react-feather'
// import { CheckCircle as CircleCheckIcon } from 'react-feather'
import { imgBaseUrl } from 'lib/cloudinary'
import api from 'lib/api'

const SpeakerView = ({ speaker, toggle, fullscreen }) => {
    
    const event = useSelector(state => state.event)
    const eventId = event?.id
    const [connected, setConnected] = useState(false)
    // const [error, setError] = useState()
    // const { t } = useTranslation()

    // const handleConnect = () => {
    //     api.post('/event/' + eventId + '/action', {
    //         type: 'speaker-connect',
    //         data: {
    //             speakerId: speaker.id
    //         }
    //     })
    //     .then(action => {
    //         setError()
    //         setConnected(true)
    //     })
    //     .catch(error => {
    //         setError(error)
    //     })
    // }

    useEffect(() => {
        setConnected(false)
    }, [speaker])
    
    return (
        <Modal show={!!speaker} onHide={toggle} centered fullscreen={fullscreen}>
            {speaker && (
                <>
                    <Modal.Header closeButton onHide={toggle}>{speaker.name}</Modal.Header>
                    <div className='position-relative'>
                        {/* {!!speaker.badge && (
                            <div style={{ position: 'absolute', top: 0, right: 0, background: event.primaryColor, color: '#fff', fontSize: '2rem', padding: '0.5rem 0.75rem', paddingBottom: '3.5rem', paddingLeft: '3.5rem', borderBottomLeftRadius: '0.5rem', clipPath: 'polygon(0 0, 100% 0, 100% 100%)' }}>★</div>
                        )} */}
                        {!!speaker.badge && (
                            <div style={{ position: 'absolute', bottom: 0, left: 0, right: 0, background: event.primaryColor, color: '#fff', textAlign: 'center', fontSize: '1rem', padding: '0.25rem', fontWeight: 'bold' }}>Winner!</div>
                        )}
                        {!!speaker.imageUrl && <img src={imgBaseUrl + '/t_square_600/' + speaker.imageUrl} alt={speaker.name} style={{ width: '100%' }} />}
                    </div>
                    <Modal.Body>
                        <h3>{speaker.name}</h3>
                        {!!speaker.detail && <p className='text-muted mt-n2'>{speaker.detail}</p>}
                        {!!speaker.description && <p className='small'>{speaker.description}</p>}
                    </Modal.Body>
                    {/* {error && <Alert variant='warning' style={{ margin: 0, borderRadius: 0 }}>{error.message}</Alert>}
                    <Button variant={connected ? 'secondary' : 'primary'} style={{ cursor: connected ? 'default' : 'pointer', padding: '1rem', borderTopLeftRadius: 0, borderTopRightRadius: 0 }} onClick={handleConnect}>
                        {connected ? (
                            <>
                                <CircleCheckIcon style={{ marginRight: '0.5rem' }} />
                                {t('connectedWithName', { name: speaker.name })}
                            </>
                        ) : (
                            <>
                                <MessageSquareIcon style={{ marginRight: '0.5rem' }} />
                                {t('connectWithName', { name: speaker.name })}
                            </>
                        )}
                    </Button> */}
                </>
            )}
        </Modal>
    )
}

export default SpeakerView