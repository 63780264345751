import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import StartupView from '../../features/startup/view'
import useSWR from 'swr'
import axios from 'axios'


const PresentationMode = () => {

    const [event, token] = useSelector(state => [state.event, state.guest?.token])
    const eventId = event?.id

    const { data: entities, error } = useSWR(
        process.env.REACT_APP_API_URL + '/client/event/' + eventId + '/startup',
        url => axios.get(url, { headers: { authorization: 'Bearer ' + token } }).then(res => res.data),
        { refreshInterval: 5 * 1000 }
    )

    const presenting = useMemo(() => {
        return entities?.reduce((presenting, entity) => !!entity.status ? entity : presenting, null)
    }, [entities])

    if (error) console.error(error)

    return presenting ? (
        <StartupView featureId={presenting?.featureId} startup={presenting} fullscreen={true} key={presenting?.id} />
    ) : null

}

export default PresentationMode