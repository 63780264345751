import featureList from 'lib/features'

const useFeatures = features => features ? features.map(feature => { // sort((a, b) => a.sort < b.sort ? -1 : 1).
    const item = featureList.find(item => item.id === feature.type)
    if (item) return {
        ...feature,
        icon: item.icon,
        component: item.component,
        url: '/feature/' + feature.id

    }
    return null
}).filter(feature => !!feature) : []

export default useFeatures